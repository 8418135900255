<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
   
   
  </div>
  <div class="d-none d-md-block text-uppercase">
    TELEMETRIA {{authService.getUser()?.empresa}}
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action">
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"  
               [name]="authService.getUser()?.name"
               [picture]="authService.getUser()?.photoUrl">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
