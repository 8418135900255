import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';


import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './core/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    requestQueue = 0

    constructor(private authService: AuthService, private router: Router,
        private spinner: NgxSpinnerService) { }

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        const token = this.authService.getUser()?.token

        if (req.headers.has('SKIP_INTERCEPTOR')) {
            if (token != null)
                req = req.clone({
                    headers: req.headers.set('Authorization', `Bearer ${token}`)
                });
            return next.handle(req)
        }


        if (this.requestQueue++ == 0) {
            this.spinner.show()
        }

        if (token != null)
            req = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${token}`)
            });

        return next.handle(req)
            .pipe(
                tap(e => {
                    if (e instanceof HttpResponse) {
                        if (--this.requestQueue == 0) {
                            this.spinner.hide()
                        }
                    }
                }),
                catchError((error: HttpErrorResponse) => {
                    if (error instanceof HttpErrorResponse) {
                        if (--this.requestQueue == 0) {
                            this.spinner.hide()
                        }
                    }
                    if (error.status == 401) {
                        if (error.url.includes('sensoresresumen')) return
                        this.authService.logout()
                        // this.router.navigate(['/auth/login'])
                    }
                    return throwError(error)
                })

            );
    }
}