import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";
import auth0 from 'auth0-js';
import { AUTH_CONFIG } from './auth0.service';
import { environment } from '../../../environments/environment';
import { User } from '../models/user';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private key = 'user'
  private user: User;

  constructor(
    private router: Router
  ) {

    this.user = JSON.parse(window.localStorage.getItem(this.key))
    this.handleMainpage(this.user?.permisos ?? [])
  }

  isAuthtenticated() {
    return this.user != null
  }

  setUser(user: User) {
    this.user = user
    window.localStorage.setItem(this.key, JSON.stringify(user))
  }

  logout() {
    this.user = null
    localStorage.clear()
    if (window.location.href.includes('ips')) {
      this.router.navigate(['/ips'])
      return
    }
    const auth01 = new auth0.WebAuth({
      clientID: AUTH_CONFIG.clientID,
      domain: AUTH_CONFIG.domain,
    });
    auth01.logout({
      returnTo: environment.logoutURL,
      clientID: AUTH_CONFIG.clientID,
    })
  }

  getUser() {
    return this.user
  }

  hasPermission(permission: string) {
    return this.getUser()?.permisos?.includes(permission)
  }

  getRol(): string {
    const decoded: any = jwt_decode(this.user.token)
    return decoded.role
  }

  private handleMainpage(permisos: string[]) {
    if (permisos.includes('ocultarInicio')) {
      this.router.navigate(['/pages/graficas/-1'], {
        queryParams: {
          waitForSensor: true
        }
      })
    }
  }



}
