import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './@core/core.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ThemeModule } from './@theme/theme.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { IMqttServiceOptions, MqttModule } from "ngx-mqtt";
import { NgxEchartsModule } from 'ngx-echarts';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../environments/environment';

const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: 'mqtt2.iforware.com',
  port: 8083,
  protocol: 'wss',
  path: '/mqtt',
  username: 'public',
  password: 'public',

};

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function adConfig(client: string) {
  return {
    ips: {
      clientId: 'bf377bab-1930-4e5f-ad6d-5d44ecded56b',
      authority: 'https://login.microsoftonline.com/4702cebe-b4d8-4b82-84ed-7d2cc2e9df58',
      redirectUri: environment.ADCallback.ips
    },
    aam: {
      clientId: '6f88d9d9-67d1-4948-90af-db9612f70f90',
      authority: 'https://login.microsoftonline.com/24a29c14-f659-4791-a5e2-4e980ec6cbeb',
      redirectUri: environment.ADCallback.aam
    }
  }[client]
}

export function MSALInstanceFactory(): IPublicClientApplication {
  const client = window.location.href.includes('ips') ? 'ips' : window.location.href.includes('aam') ? 'aam' : ''
  return new PublicClientApplication({
    auth: adConfig(client),
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    }
  };
}

const AD_PROVIDERS = [
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory
  },
  MsalService,
  MsalGuard,
  MsalBroadcastService
]

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    HttpClientModule,
    NgxSpinnerModule,
    MsalModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent, MsalRedirectComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ...AD_PROVIDERS
  ],
})
export class AppModule {
}
