import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-app',
  template: `
  <ngx-spinner bdColor = "rgba(0,0,0,0.39)" size = "medium" color = "#fff" type = "timer" [fullScreen] = "true"><p style="color: white" > Cargando... </p></ngx-spinner>
  <router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  ngOnInit(): void {

  }
}
